<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="60"
        viewBox="0 0 100 60"
        fill="none"
    >
        <path
            d="M0 37.61C0 31.3556 4.71724 26.3822 12.0126 26.3822C14.031 26.3822 15.9537 26.6946 17.3208 27.2272V33.5131C15.831 32.8254 14.3411 32.4186 12.5724 32.4186C8.75405 32.4186 6.51924 34.6705 6.51924 37.61C6.51924 40.5496 8.75405 42.8015 12.5724 42.8015C14.3411 42.8015 15.831 42.3947 17.3208 41.707V47.9929C15.9558 48.5254 14.031 48.8379 12.0126 48.8379C4.71724 48.8379 0 43.8645 0 37.61Z"
            fill="#282828"
        />
        <path
            d="M19.338 40.8957C19.338 36.5178 22.3178 32.9828 26.6625 32.9828C28.5249 32.9828 29.8587 33.6391 30.7597 34.484V33.3895H36.1907V48.4019H31.0073L30.8825 47.2131C29.9503 48.121 28.5852 48.8087 26.6605 48.8087C22.3157 48.8087 19.3359 45.2736 19.3359 40.8957H19.338ZM30.9782 40.8957C30.9782 39.1135 29.6444 37.7675 27.9984 37.7675C26.3525 37.7675 25.0187 39.1114 24.9875 40.8957C24.9875 42.6779 26.3213 44.024 27.9984 44.024C29.6756 44.024 30.9782 42.68 30.9782 40.8957Z"
            fill="#282828"
        />
        <path
            d="M63.0405 38.2052V48.4015H56.9249V40.0503C56.9249 39.0187 56.2112 38.4233 55.4038 38.4233C54.5965 38.4233 53.914 39.0166 53.914 40.0503V48.4015H47.7984V40.0503C47.7984 39.0187 47.1159 38.4233 46.2773 38.4233C45.4387 38.4233 44.7874 39.0166 44.7874 40.0503V48.4015H38.6719V33.2947H44.415V34.7331C45.4075 33.6071 46.6186 32.8251 48.791 32.8251C50.6221 32.8251 52.112 33.6386 53.013 34.9826C54.1928 33.6386 55.6202 32.8251 57.9175 32.8251C60.9284 32.8251 63.0384 35.0455 63.0384 38.2052H63.0405Z"
            fill="#282828"
        />
        <path
            d="M65.5254 54.9998V33.2947H71.3913L71.5473 34.4836C72.4483 33.5757 73.8133 32.8251 75.8317 32.8251C80.2077 32.8251 83.1563 36.4209 83.1563 40.8324C83.1563 45.2438 80.2077 48.8397 75.8317 48.8397C73.8446 48.8397 72.542 48.1205 71.641 47.2755V54.9998H65.5254ZM76.7931 40.8324C76.8243 39.2682 75.6133 38.0479 74.1234 38.0479C72.6335 38.0479 71.4537 39.2682 71.4537 40.8324C71.4537 42.3965 72.6647 43.6461 74.1234 43.6461C75.582 43.6461 76.7931 42.4259 76.7931 40.8324Z"
            fill="#282828"
        />
        <path
            d="M84.7383 47.7747V43.2395C85.8245 43.8329 87.2207 44.2396 88.4005 44.2396C89.3307 44.2396 89.9841 43.9901 89.9841 43.3632C89.9841 41.5181 84.7383 42.0801 84.7383 37.7021C84.7383 34.6997 87.1292 32.8231 91.5051 32.8231C93.4923 32.8231 94.9822 33.1984 96.3784 33.9176V38.4842C94.951 37.4526 93.8336 37.1717 92.9326 37.1717C92.0316 37.1717 91.5988 37.4841 91.5988 38.0166C91.5988 39.8303 97.0006 39.1426 97.0006 43.835C97.0006 46.5565 95.2319 48.714 90.1401 48.714C88.0593 48.714 86.353 48.3387 84.7383 47.7768V47.7747Z"
            fill="#282828"
        />
        <path
            d="M21.9727 30.3958V8.81445H36.064V13.7878H28.1798V17.1027H35.444V21.2311H28.1798V25.4224H36.064V30.3958H21.9727Z"
            fill="#F06400"
        />
        <path
            d="M38.4236 30.3955V7.87695H44.5391V30.3955H38.4236Z"
            fill="#F06400"
        />
        <path
            d="M47.0215 30.3958V15.2891H53.137V30.3958H47.0215Z"
            fill="#F06400"
        />
        <path
            d="M62.6029 23.577C62.6029 24.8602 63.2542 25.6108 64.6213 25.6108C65.6763 25.6108 66.4836 25.3299 67.7259 24.6736L65.7699 30.0852C64.7774 30.4919 63.6599 30.71 62.2928 30.71C58.9094 30.71 56.4894 28.9278 56.4894 24.3926V20.1699H54.7207V15.2909H56.4894V11.9445L62.605 10.7242V15.2909H68.192L66.5773 20.1699H62.605V23.5791L62.6029 23.577Z"
            fill="#F06400"
        />
        <path
            d="M74.4304 24.0781C75.0817 25.3299 76.5716 26.1727 79.2101 26.1727C80.7 26.1727 82.4375 25.9232 83.9898 25.3592L81.7238 30.2068C80.6063 30.6136 79.2101 30.8316 77.7202 30.8316C72.1332 30.8316 68.5625 27.5167 68.5625 22.8243C68.5625 18.1319 71.9771 14.817 77.0981 14.817C80.4815 14.817 82.9951 16.4126 84.2686 18.4443V24.0739H74.4284L74.4304 24.0781ZM74.3056 21.1386H80.2027C79.7366 19.826 78.6816 18.9811 77.2541 18.9811C75.8267 18.9811 74.7405 19.8889 74.3056 21.1386Z"
            fill="#F06400"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49.8255 5.10274C49.8245 5.10274 49.8239 5.10172 49.8243 5.10082C49.8517 5.03903 49.9115 5 49.9774 5C50.044 5 50.1043 5.03984 50.1314 5.10274L51.255 7.74039C51.2862 7.81167 51.3528 7.862 51.4298 7.86829L54.266 8.13037C54.3326 8.13666 54.3888 8.18279 54.4096 8.24569C54.4304 8.30859 54.4096 8.37988 54.3617 8.42391L52.2185 10.3151C52.1602 10.3676 52.1352 10.4472 52.1519 10.5227L52.7824 13.3218C52.7969 13.3868 52.772 13.456 52.7179 13.4937C52.6638 13.5336 52.5909 13.5357 52.5347 13.5021L50.0877 12.0344C50.0211 11.9946 49.9379 11.9946 49.8713 12.0344L47.4242 13.5021C47.368 13.5357 47.2952 13.5336 47.2411 13.4937C47.187 13.4539 47.162 13.3868 47.1766 13.3218L47.8071 10.5227C47.8237 10.4472 47.7988 10.3655 47.7405 10.3151L45.5972 8.42391C45.5473 8.37988 45.5286 8.30859 45.5494 8.24569C45.5702 8.18069 45.6264 8.13666 45.693 8.13037L48.5291 7.86829C48.6061 7.862 48.6748 7.81167 48.7039 7.74039L49.8268 5.10465C49.8272 5.10374 49.8265 5.10274 49.8255 5.10274Z"
            fill="#F06400"
        />
    </svg>
</template>
